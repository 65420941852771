<template>
  <section id="herosub2">
    <v-img
      :src="require('@/assets/BS_Shows_Header.jpg')"
      class="white--text bg hero"
      height="auto"
      width="100%"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="auto"
          max-width="700"
          width="100%"
        />
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '60vh' : '10vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
<style scoped>
.bg{
  background-size: cover;
  no-repeat: center center fixed !important;
}

</style>
